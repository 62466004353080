import { Injectable, inject } from '@angular/core'
import { KeysService } from './keys.service'
import { LogService } from './log.service'
import { EnvironmentService } from './environment.service'

declare global {
  interface Window {
      gtag: any
  }
}

@Injectable()
export class AnalyticsService {
  private _logger: LogService = inject(LogService)
  private keysService: KeysService = inject(KeysService)
  private environmentService: EnvironmentService = inject(EnvironmentService)
  window = inject(Window)
  googleAnalyticsID = this.environmentService.get().googleAnalytics.trackingId

  init() {
    this._logger.debug('AnalyticsService.init()')
    this.loadGoogleAnalytics()
  }

  loadGoogleAnalytics() {
    this._logger.debug('AnalyticsService.loadGoogleAnalytics()')
    const keys = this.keysService.keys()

    new Promise((resolve) => {
      this._addGoogleAnalyticsElement(this.googleAnalyticsID)
      resolve(1)
    }).then(() => {
      this.window.gtag('config', this.googleAnalyticsID)
      this.window.gtag('js', new Date())
    })

    if (!keys.google_analytics?.length) {
      this._logger.debug('AnalyticsService.loadGoogleAnalytics() - No keys found')
      return
    }

    const codes = keys.google_analytics

    Promise.all([
      new Promise((resolve) => {
        this._addGoogleAnalyticsElement(codes[0].id)
        resolve(1)
      }).then(() => {
        codes.forEach(key => {
          this.window.gtag('config', key.id)
          this.window.gtag('js', new Date())
        })
      })
    ])
  }

  private _addGoogleAnalyticsElement(id: string) {
    this._logger.debug('AnalyticsService.addGoogleAnalyticsElement()', id)
    const script = document.createElement('script')
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    script.async = true
    document.body.appendChild(script)
  }

  trackEvent(event: string, properties: any) {
    if (!this.window.gtag) {
      this._logger.warn('AnalyticsService.trackEvent() - Failed to track event.  Google analytics not loaded.')
      return
    }
    this.window.gtag('event', event, properties)
  }
}
