import { inject, Injectable } from '@angular/core'
import { HeapService } from './heap.service'
import { AnalyticsService } from './analytics.service'

export enum UserEvent {
  FleetAssetId = 'fleet_asset_id',
  ClientIdRouseSales = 'client_id_rouse_sales',
  FilterOpened = 'Filter Opened',
  DrilldownOptionAdded = 'Drilldown Option Added',
  SearchNearMeClicked = 'Search Near Me Clicked',
  ResultsSorted = 'Results Sorted',
  SearchIconClicked = 'Search Icon Clicked',
  HeaderMenuClicked = 'Header Menu Clicked',
  CarouselClicked = 'Carousel Clicked',
}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private _heapService = inject(HeapService)
  private _analyticsService = inject(AnalyticsService)
  trackEvent(event: UserEvent, data: any = {}): void {
    this._heapService.trackEvent(event, data)
    this._analyticsService.trackEvent(event, data)
  }
}
