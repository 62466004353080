import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatButtonModule } from '@angular/material/button'
import { RouterModule } from '@angular/router'
import { IconButtonBlackDirective, IconButtonIconLargeDirective, IconButtonIconMediumDirective, IconButtonIconOnlyDirective, IconButtonIconSmallDirective, IconButtonIconXSmallDirective, IconButtonMediumDirective, IconButtonOutlinedDirective, IconButtonPrimaryDirective, IconButtonSecondaryDirective, IconButtonSmallDirective, IconButtonUnstyledDirective, IconButtonWhiteDirective } from './icon-button.directive'
import { IconButtonComponent } from './icon-button.component'
import { MatIconModule } from '@angular/material/icon'

@NgModule({
  declarations: [
    IconButtonComponent,
    IconButtonUnstyledDirective,
    IconButtonIconOnlyDirective,
    IconButtonOutlinedDirective,
    IconButtonPrimaryDirective,
    IconButtonSecondaryDirective,
    IconButtonWhiteDirective,
    IconButtonBlackDirective,
    IconButtonSmallDirective,
    IconButtonMediumDirective,
    IconButtonIconXSmallDirective,
    IconButtonIconSmallDirective,
    IconButtonIconMediumDirective,
    IconButtonIconLargeDirective,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
  ],
  exports: [
    IconButtonComponent,
    IconButtonUnstyledDirective,
    IconButtonIconOnlyDirective,
    IconButtonOutlinedDirective,
    IconButtonPrimaryDirective,
    IconButtonSecondaryDirective,
    IconButtonWhiteDirective,
    IconButtonBlackDirective,
    IconButtonSmallDirective,
    IconButtonMediumDirective,
    IconButtonIconXSmallDirective,
    IconButtonIconSmallDirective,
    IconButtonIconMediumDirective,
    IconButtonIconLargeDirective,
  ],
})
export class IconButtonModule { }
