import { IDrilldownTiersConfig } from '../interfaces/drilldown-filter.interface'
import { IFilterItem } from '../interfaces/filter.interface'

export const mockFilterFieldsConfig: IDrilldownTiersConfig = {
  field_1: {
    parent: null,
    display: 'Tier 1',
  },
  field_2: {
    parent: 'field_1',
    display: 'Tier 2',
  },
  field_3: {
    parent: 'field_2',
    display: 'Tier 3',
  },
}

export const mockFilterSettings: IFilterItem[] = [
  {
    value: 'Parent w/ children',
    field: 'field_1',
    children: [
      {
        value: 'Child w/ children',
        field: 'field_2',
        children: [
          {
            value: 'Grandchild 1',
            field: 'field_3',
          },
          {
            value: 'Grandchild 2',
            field: 'field_3',
          },
        ],
      },
      {
        value: 'Child w/ no children',
        field: 'field_2',
      },
    ],
  },
  {
    value: 'Parent w/ no children',
    field: 'field_1',
  },
  {
    value: 'Some other parent',
    field: 'field_1',
    children: [
      {
        value: 'Some other child',
        field: 'field_2',
        children: [
          {
            value: 'Some other grandchild',
            field: 'field_3',
          },
        ],
      },
    ],
  },
]
