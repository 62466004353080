import { CurrencyCode } from '../interfaces/currency.interface'
import { LanguageCode } from '../interfaces/language.interface'
import { IComponentConfig, IConfig, IConfigMetadata, IPageConfig } from 'src/app/core/interfaces/config.interface'
import { IApiAllConfigsResponse } from '../interfaces/init-data.interface'
import { IKeys } from '../interfaces/keys.interface'
import { ImageSizes } from '../interfaces/media-library.interface'
import { IClient } from '../interfaces/client.interface'
import { I18nText } from '../interfaces/i18n.service'

const headerConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    title: 'Header Text',
    logo: {
      src_data: {
        name: 'XYZ_Logo-a6e68e87e8d5.png',
        kb: 0,
        urls: {
          '250x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.250x0xc.png',
          '32x32xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.32x32xc.png',
          '2000x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x0xc.png',
          '648x486xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.648x486xc.png',
          '1200x630xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.1200x630xc.png',
          '2000x800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x800xc.png',
          '2000x1500xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x1500xc.png',
          '2000x1800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x1800xc.png'
        },
        width: 0,
        height: 0,
        updated: '2024-05-07T17:07:20.577Z',
        updatedFormatted: '5/7/2024'
      },
      alt: 'Rouse Logo',
      display: true,
      redirect: 'https://www.rouseservices.com/solutions/fleet-manager/'
    },
    styles: {
      headerBasic: {
        '--nav-item-color': '#002554',
        '--nav-item-hover': '#002554',
        '--nav-currency-color': '#002554'
      }
    },
    components: {}
  }
}

const footerConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    address: '8383 Wilshire  Blvd. Suite 900',
    address_line_2: 'Beverly Hills, CA 90211',
    phone: '(301) 360-9200',
    email: 'contact@rouseservices.com',
    instagram: 'https://instagram.com/catalog',
    facebook: 'https://facebook.com/catalog',
    twitter: 'https://twitter.com/catalog',
    linkedin: 'https://linkedin.com/catalog',
    styles: {
      footer: {
        'background-color': '#002554'
      }
    },
    components: {},
    youtube: 'https://youtube.com/catalog'
  }
}

const legalTermsConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    terms: 'this thing is different from the other thing',
    styles: {},
    components: {},
  },
}

const contactFormConfig: IComponentConfig = {
  enabled: true,
  template: 'basic',
  data: {
    successTitle: {
      'en-US': 'Thank You!'
    },
    successBody: {
      'en-US': 'We have received your submission and will be in touch soon!'
    },
    styles: {},
    components: {
      assetCard: {
        enabled: true,
        template: 'contact-form',
        data: {
          styles: {},
          components: {
            badgesContainer: {
              enabled: false,
              template: 'basic',
              data: {
                styles: {},
                components: {}
              }
            }
          }
        }
      }
    }
  }
}

const homeConfig: IPageConfig = {
  seo: {
    data: {
      title: {
        'en-US': '<siteTitle> test',
        'en-CA': 'Home - Used Equipment',
        'es-MX': 'Inicio - Equipos Usados',
        'es-SP': 'Inicio - Equipos Usados',
        'fr-CA': 'Accueil - Équipements Usagés'
      },
      description: {
        'en-US': '<siteDescription>'
      } as I18nText
    }
  },
  components: {
    heroBanner: {
      enabled: true,
      template: 'basic',
      data: {
        mainText: {
          'en-US': 'Browse Equipment that Meets Your Needs'
        },
        mainTextSize: 'h3',
        bannerSize: 'medium',
        subText: {
          'en-US': 'Search our Inventory of Quality Used Machines'
        },
        ctaText: {
          'en-US': 'View Inventory'
        },
        bannerAltText: {
          'en-US': 'Hero Banner Image'
        },
        quantity: '3',
        styles: {
          content: {
            '--main-content-color': '#ffffff'
          },
          container: {
            '--overlay-bg-color': 'rgba(0,37,84,0.39)'
          }
        },
        components: {
          search: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {}
            }
          }
        },
        imageToggle: true,
        imageSrcData: {
          kb: 321.3,
          name: 'Construction-machines.jpg',
          urls: {
            full: 'https://media.rouseservices.com/xyzs/Construction-machines.0x0xc.jpg',
            unsized: 'https://media.rouseservices.com/source_images/xyzs/Construction-machines.jpg',
            thumbnail: 'https://media.rouseservices.com/xyzs/Construction-machines.168x122xc.jpg',
            '250x0xc': 'https://media.rouseservices.com/xyzs/Construction-machines.250x0xc.jpg',
            '32x32xc': 'https://media.rouseservices.com/xyzs/Construction-machines.32x32xc.jpg',
            '2000x0xc': 'https://media.rouseservices.com/xyzs/Construction-machines.2000x0xc.jpg',
            '648x486xc': 'https://media.rouseservices.com/xyzs/Construction-machines.648x486xc.jpg',
            '1200x630xc': 'https://media.rouseservices.com/xyzs/Construction-machines.1200x630xc.jpg',
            '2000x800xc': 'https://media.rouseservices.com/xyzs/Construction-machines.2000x800xc.jpg',
            '2000x1500xc': 'https://media.rouseservices.com/xyzs/Construction-machines.2000x1500xc.jpg',
            '2000x1800xc': 'https://media.rouseservices.com/xyzs/Construction-machines.2000x1800xc.jpg'
          },
          width: 0,
          height: 0,
          loaded: true,
          updated: '2024-02-08T21:07:09.324000+00:00',
          updatedFormatted: '2/8/2024'
        }
      }
    },
    featuredCarousel: {
      enabled: true,
      template: 'basic',
      data: {
        quantity: 6,
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    labelType: 'reversed',
                    labelModifier: 'secondary',
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                          labelType: 'reversed',
                          labelModifier: 'secondary'
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    browseByCategory: {
      enabled: true,
      template: 'basic',
      data: {
        quantity: '8',
        styles: {},
        components: {},
        display: true
      }
    },
    browseByMake: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
        quantity: 5,
      }
    },
    contactFormSection: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {
          content: {
            'align-items': 'center'
          }
        },
        components: {},
        mainText: {
          'en-US': 'Contact Us!'
        }
      }
    }
  }
}

const searchConfig: IPageConfig = {
  seo: {
    data: {
      title: {
        'en-US': 'Search - <siteTitle>',
        'en-CA': 'Used Equipment',
        'es-MX': 'Equipos Usados',
        'es-SP': 'Equipos Usados',
        'fr-CA': 'Équipements Usagés'
      },
      description: {
        'en-US': '<siteDescription>'
      } as I18nText
    }
  },
  components: {
    search: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {}
      }
    },
    searchGrid: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              enableButtons: false,
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    filterContainer: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {
          filterContainer: {
            '--chip-background-color': '#002554'
          }
        },
        components: {}
      }
    },
    sort: {
      data: {
        styles: {},
        components: {}
      },
      enabled: true,
      template: 'basic'
    }
  }
}

const detailsConfig: IPageConfig = {
  seo: {
    data: {
      title: {
        'en-US': '<year> <make> <model> - <siteTitle>',
        'en-CA': '<year> <make> <model> <category>',
        'es-MX': '<year> <make> <model> <category>',
        'es-SP': '<year> <make> <model> <category>',
        'fr-CA': '<year> <make> <model> <category>'
      },
      description: {
        'en-US': '<siteDescription>'
      } as I18nText
    }
  },
  components: {
    shareButton: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {}
      }
    },
    leadForm: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {},
        showContactSales: true,
        downloadFlyer: true,
        showEmail: true,
        showPhoneNumber: true
      }
    },
    quickLinksBanner: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {
          quickLinksBanner: {
            '--text-color': '#002554'
          }
        },
        components: {}
      }
    },
    similarCarousel: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                          labelType: 'reversed',
                          labelModifier: 'secondary'
                        }
                      }
                    },
                    labelType: 'reversed',
                    labelModifier: 'secondary'
                  }
                }
              }
            }
          }
        },
        quantity: 6
      }
    },
    recentlyViewedCarousel: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {
          assetCard: {
            enabled: true,
            template: 'basic',
            data: {
              styles: {},
              components: {
                badgesContainer: {
                  enabled: true,
                  template: 'basic',
                  data: {
                    styles: {},
                    components: {
                      badge: {
                        enabled: true,
                        template: 'basic',
                        data: {
                          styles: {},
                          components: {},
                          labelType: 'reversed',
                          labelModifier: 'secondary'
                        }
                      }
                    },
                    labelType: 'reversed',
                    labelModifier: 'secondary'
                  }
                }
              }
            }
          }
        },
        quantity: 6
      }
    },
    assetDetailsHeader: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {
          assetDetailsHeader: {
            '--text-color': '#ffffff',
            '--background-color': '#002554'
          }
        },
        components: {}
      }
    },
    assetInformation: {
      enabled: true,
      template: 'basic',
      data: {
        showConfiguration: true,
        styles: {},
        components: {}
      }
    },
    shippingBanner: {
      enabled: true,
      template: 'basic',
      data: {
        styles: {},
        components: {}
      }
    }
  }
}

export const xyzMockConfigData: IConfig = {
  version: '4',
  sitemap: {
    static: [
      '/home',
      '/equipment/search/'
    ],
    dynamic: [
      {
        url: '/equipment/details/',
        id: 'equipment.equipment_number',
        filters: [
          {
            'equipment_marketing.is_listed': true
          }
        ]
      }
    ]
  },
  seo: {
    data: {
      favicon_img_data: {
        width: 0,
        height: 0,
        kb: 0.9,
        name: 'XYZ_Logo-_Fav-e9d13248d7e0.png',
        originalFileName: '',
        updated: '2024-04-10T15:34:54.807000+00:00',
        updatedFormatted: '4/10/2024',
        urls: {
          full: 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.0x0xc.png',
          unsized: 'https://media.rouseservices.com/source_images/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.png',
          thumbnail: 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.168x122xc.png',
          '32x32xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.32x32xc.png',
          '250x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.250x0xc.png',
          '2000x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.2000x0xc.png',
          '648x486xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.648x486xc.png',
          '1200x630xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.1200x630xc.png',
          '2000x800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.2000x800xc.png',
          '2000x1500xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.2000x1500xc.png',
          '2000x1800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-_Fav-e9d13248d7e0.2000x1800xc.png'
        },
        loaded: true
      },
      social_img_data: {
        height: 0,
        width: 0,
        kb: 10.3,
        name: 'XYZ_Logo-a6e68e87e8d5.png',
        originalFileName: '',
        updated: '2024-03-28T19:47:37.821000+00:00',
        updatedFormatted: '3/28/2024',
        urls: {
          full: 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.0x0xc.png',
          unsized: 'https://media.rouseservices.com/source_images/xyzs/XYZ_Logo-a6e68e87e8d5.png',
          thumbnail: 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.168x122xc.png',
          '1200x630xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.1200x630xc.png',
          '250x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.250x0xc.png',
          '32x32xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.32x32xc.png',
          '2000x0xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x0xc.png',
          '648x486xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.648x486xc.png',
          '2000x800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x800xc.png',
          '2000x1500xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x1500xc.png',
          '2000x1800xc': 'https://media.rouseservices.com/xyzs/XYZ_Logo-a6e68e87e8d5.2000x1800xc.png'
        },
        loaded: true
      },
    },
    url: [
      {
        path: '/equipment/detail/',
        meta_group: 'detail-equipment'
      },
      {
        path: '/category/detail/',
        meta_group: 'detail-category'
      },
      {
        path: '/equipment/search',
        meta_group: 'equipment'
      },
      {
        path: '/category/search',
        meta_group: 'sub-category'
      }
    ],
    tags: [
      {
        name: 'title',
        content: 'XYZ : Construction, Engineering and Mining Services',
        element: 'title'
      },
      {
        name: 'description',
        content: 'Construction, Engineering and Mining Services',
        element: 'meta'
      },
      {
        name: 'keywords',
        content: 'search, used equipment, XYZ, equipment sales, construction, shopping, generator rentals, lift rental, tool rental, sun state, forklift rentals, rentals',
        element: 'meta'
      },
      {
        name: 'robots',
        content: 'index,follow',
        element: 'meta'
      },
      {
        name: 'og:type',
        content: 'website',
        element: 'meta'
      },
      {
        name: 'og:title',
        content: 'XYZ : Construction, Engineering and Mining Services',
        element: 'meta'
      },
      {
        name: 'og:description',
        content: 'Construction, Engineering and Mining Services',
        element: 'meta'
      },
      {
        name: 'og:site_name',
        content: 'XYZ Equipment',
        element: 'meta'
      },
      {
        name: 'og:image',
        content: 'https://catalog-assets.rousesales.com/_default/content/no-photo.jpeg',
        element: 'meta'
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
        element: 'meta'
      },
      {
        name: 'twitter:description',
        content: 'Construction, Engineering and Mining Services',
        element: 'meta'
      },
      {
        name: 'twitter:title',
        content: 'Construction, Engineering and Mining Services',
        element: 'meta'
      },
      {
        name: 'twitter:image',
        content: 'https://catalog-assets.rousesales.com/_default/content/no-photo.jpeg',
        element: 'meta'
      }
    ],
    default: {
      'og:image': 'https://catalog-assets.rousesales.com/_default/content/no-photo.jpeg',
      'twitter:image': 'https://catalog-assets.rousesales.com/_default/content/no-photo.jpeg'
    },
    meta_group: {
      equipment: [
        {
          name: 'title',
          content: 'XYZ : Search - Find Used Equipment',
          element: 'title'
        },
        {
          name: 'keywords',
          content: 'search, used equipment, XYZ, equipment sales, construction, shopping, generator rentals, lift rental, tool rental, sun state, forklift rentals, rentals ',
          element: 'meta'
        },
        {
          name: 'og:title',
          content: 'XYZ : Search - Find Used Equipment',
          element: 'meta'
        },
        {
          name: 'twitter:title',
          content: 'XYZ : Search - Find Used Equipment',
          element: 'meta'
        },
        {
          name: 'description',
          content: 'Construction, Engineering and Mining Services',
          element: 'meta'
        },
        {
          name: 'og:description',
          content: 'Find the right used equipment for your next job. Search the XYZ Equipment inventory construction equipment.',
          element: 'meta'
        },
        {
          name: 'twitter:description',
          content: 'Construction, Engineering and Mining Services',
          element: 'meta'
        }
      ],
      'sub-category': [
        {
          name: 'title',
          content: 'XYZ : Search - Find Used Equipment',
          element: 'title'
        },
        {
          name: 'keywords',
          content: 'search, used equipment, XYZ, equipment sales, construction, shopping, generator rentals, lift rental, tool rental, sun state, forklift rentals, rentals ',
          element: 'meta'
        },
        {
          name: 'og:title',
          content: 'XYZ : Search Category - Find Used Equipment',
          element: 'meta'
        },
        {
          name: 'twitter:title',
          content: 'XYZ : Search - Find Used Equipment',
          element: 'meta'
        },
        {
          name: 'description',
          content: 'Construction, Engineering and Mining Services',
          element: 'meta'
        },
        {
          name: 'og:description',
          content: 'Construction, Engineering and Mining Services',
          element: 'meta'
        },
        {
          name: 'twitter:description',
          content: 'Construction, Engineering and Mining Services',
          element: 'meta'
        }
      ],
      'detail-category': [
        {
          name: 'title',
          content: 'XYZ : {{sub_category}}',
          element: 'title'
        },
        {
          name: 'keywords',
          content: 'Details, used equipment, XYZ, equipment sales, construction, shopping, generator rentals, lift rental, tool rental, sun state, forklift rentals, rentals',
          element: 'meta'
        },
        {
          name: 'og:image',
          content: 'https://images.rouseservices.com/ImageProcessor/get/getimage.aspx?type=ItemDetailLarge&guid={{img}}',
          element: 'meta'
        },
        {
          name: 'twitter:image',
          content: 'https://images.rouseservices.com/ImageProcessor/get/getimage.aspx?type=ItemDetailLarge&guid={{img}}',
          element: 'meta'
        },
        {
          name: 'og:title',
          content: 'XYZ : {{sub_category}}',
          element: 'meta'
        },
        {
          name: 'twitter:title',
          content: 'XYZ : {{sub_category}}',
          element: 'meta'
        },
        {
          name: 'description',
          content: 'XYZ : {{sub_category}} ',
          element: 'meta'
        },
        {
          name: 'og:description',
          content: 'XYZ : {{sub_category}}',
          element: 'meta'
        },
        {
          name: 'twitter:description',
          content: 'XYZ : {{sub_category}}',
          element: 'meta'
        }
      ],
      'detail-equipment': [
        {
          name: 'title',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'title'
        },
        {
          name: 'keywords',
          content: 'Details, used equipment, XYZ, equipment sales, construction, shopping, generator rentals, lift rental, tool rental, sun state, forklift rentals, rentals',
          element: 'meta'
        },
        {
          name: 'og:image',
          content: 'https://images.rouseservices.com/ImageProcessor/get/getimage.aspx?type=ItemDetailLarge&guid={{img}}',
          element: 'meta'
        },
        {
          name: 'twitter:image',
          content: 'https://images.rouseservices.com/ImageProcessor/get/getimage.aspx?type=ItemDetailLarge&guid={{img}}',
          element: 'meta'
        },
        {
          name: 'og:title',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'meta'
        },
        {
          name: 'twitter:title',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'meta'
        },
        {
          name: 'description',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'meta'
        },
        {
          name: 'og:description',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'meta'
        },
        {
          name: 'twitter:description',
          content: 'XYZ : {{year}} {{make}} {{model}}',
          element: 'meta'
        }
      ]
    }
  },
  general: {
    data: {
      default_img_data: {
        height: 0,
        width: 0,
        kb: 0,
        name: '',
        originalFileName: '',
        updated: '',
        updatedFormatted: '',
        urls: {
          [ImageSizes.Favicon]: '',
        }
      },
      watermark_logo: false,
      contact_form_acknowledgement_enabled: false,
    }
  },
  currency: {
    available: [CurrencyCode.USD, CurrencyCode.CAD],
    default: CurrencyCode.USD,
    enabled: true,
    template: 'basic',
    data: {
      styles: {},
      components: {},
    },
  },
  language: {
    options: {
      base: {
        available: [LanguageCode.EN_US, LanguageCode.ES_SP],
        default: LanguageCode.EN_US,
      }
    },
  },
  theme: {
    id: 'dark-theme',
    fonts: {},
    overrides: {
      'font-color': 'green',
      'heading-font-color': 'blue',
    },
  },
  header: headerConfig,
  footer: footerConfig,
  legalTerms: legalTermsConfig,
  contactForm: contactFormConfig,
  page: {
    home: homeConfig,
    search: searchConfig,
    details: detailsConfig,
  },
  pages: [
    '404',
    'maintenance'
  ],
}

export const mockClientData: IClient = {
  id: 123,
  code: 'MOCK',
  client_code: 'MOCK',
  sales_code: 'MOCK',
  name: 'Mock Client',
  domain: 'mock-client.webshop.develop.rouseservices.com',
  domain_status_request_id: 'mock-client.webshop.develop.rouseservices.com-1234567890',
  channel_webshop_selling_contact_email: 'mock@mock.com',
  channel_webshop_selling_contact_form_submission_email: 'mock_sub@mock.com',
  channel_webshop_selling_contact_phone: '1234567890',
  email_client_contact: '',
  email_client_logo: undefined,
  email_client_logo_data: undefined,
  webshop_is_public: true,
  webshop_unit_of_distance: 'miles',
}

export const mockKeysData: IKeys = {
  heap_key: '3762971474',
  recaptcha_site_key: 'test-key',
  google_webfont_key: 'test-key',
  google_tag_manager: 'test-key',
  recaptcha_secret_key: true,
  google_analytics: [
    {
      name: 'mock google analytics',
      id: 'mock-google-analytics-id',
    },
    {
      name: 'mock google analytics 2',
      id: 'mock-google-analytics-id-2',
    }
  ],
}

export const mockConfigMetadata: IConfigMetadata = {
  id: 0,
  is_live: false,
  name: 'Mock Config',
  date_modified: '2023-09-20 19:09:16',
}

export const allConfigs: IApiAllConfigsResponse = {
  client: mockClientData,
  results: [{
    ...mockConfigMetadata,
    id: 1,
    name: 'Mock Config 1',
    config: {
      ...xyzMockConfigData,
    }
  }, {
    ...mockConfigMetadata,
    id: 2,
    name: 'Mock Config 2',
    is_live: true,
    config: {
      ...xyzMockConfigData,
    }
  }, {
    ...mockConfigMetadata,
    id: 3,
    name: 'Mock Config 3',
    config: {
      ...xyzMockConfigData,
    }
  }]
}
