import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'
import { CurrencyCode } from '../interfaces/currency.interface'
import { IAsset } from '../interfaces/asset.interface'
import { ConfigSection } from 'src/app/core/interfaces/config.interface'
import { LanguageCode } from '../interfaces/language.interface'
import { IChip } from '../interfaces/chip.interface'

@Injectable()
export class EventService {
  private configUpdatedEvent = new Subject<ConfigSection | void>()
  private clientUpdatedEvent = new Subject<void>()
  private configDirtyEvent = new Subject<boolean>()
  private configSavedLocallyEvent = new Subject<void>()
  private currenciesChangedEvent = new Subject<void>()
  private currencySelectedEvent = new Subject<CurrencyCode>()
  private languagesChangedEvent = new Subject<void>()
  private languageSelectedEvent = new Subject<LanguageCode>()
  private contactClickedEvent = new Subject<IAsset>()
  private chipRemovedEvent = new Subject<IChip>()
  private allChipsRemovedEvent = new Subject<void>()
  private filterClickedEvent = new Subject<void>()
  private filterChangedEvent = new Subject<void>()
  private navMenuClickedEvent = new Subject<boolean>()
  private mediaLibraryUploadedEvent = new Subject<{ originalFileName: string; fileName: string }>()
  private mediaLibraryUploadedFailedEvent = new Subject<{ originalFileName: string }>()
  private iframeNeedsRefreshEvent = new Subject<void>()
  private clearComparisonAssetEvent = new Subject<string>()
  private clearAllComparisonAssetsEvent = new Subject<void>()

  configUpdated$ = this.configUpdatedEvent.asObservable()
  clientUpdated$ = this.clientUpdatedEvent.asObservable()
  configDirtyChanged$ = this.configDirtyEvent.asObservable()
  configSavedLocallyEvent$ = this.configSavedLocallyEvent.asObservable()
  currenciesChanged$ = this.currenciesChangedEvent.asObservable()
  currencySelected$ = this.currencySelectedEvent.asObservable()
  languagesChanged$ = this.languagesChangedEvent.asObservable()
  languageSelected$ = this.languageSelectedEvent.asObservable()
  contactClicked$ = this.contactClickedEvent.asObservable()
  chipRemoved$ = this.chipRemovedEvent.asObservable()
  allChipsRemoved$ = this.allChipsRemovedEvent.asObservable()
  filterClicked$ = this.filterClickedEvent.asObservable()
  filterChanged$ = this.filterChangedEvent.asObservable()
  navMenuClicked$ = this.navMenuClickedEvent.asObservable()
  mediaLibraryUploaded$ = this.mediaLibraryUploadedEvent.asObservable()
  mediaLibraryFileUploadFailed$ = this.mediaLibraryUploadedFailedEvent.asObservable()
  iframeNeedsRefresh$ = this.iframeNeedsRefreshEvent.asObservable()
  clearComparisonAsset$ = this.clearComparisonAssetEvent.asObservable()
  clearAllComparisonAssets$ = this.clearAllComparisonAssetsEvent.asObservable()

  notify(eventId: string, data?: any): void {
    switch (eventId) {
      case 'contactClicked': {
        this.notifyContactClicked(data)
        break
      }
      case 'navMenuClicked': {
        this.notifyNavMenuClicked(data)
        break
      }
    }
  }

  notifyNavMenuClicked(data: boolean) {
    this.navMenuClickedEvent.next(data)
  }

  notifyConfigUpdated(section?: ConfigSection): void {
    this.configUpdatedEvent.next(section)
  }

  notifyClientUpdated(): void {
    this.clientUpdatedEvent.next()
  }

  notifyConfigDirtyChanged(dirtyState: boolean): void {
    this.configDirtyEvent.next(dirtyState)
  }

  notifyConfigSavedLocally(): void {
    this.configSavedLocallyEvent.next()
  }

  notifyCurrenciesChanged(): void {
    this.currenciesChangedEvent.next()
  }

  notifyCurrencySelected(code: CurrencyCode): void {
    this.currencySelectedEvent.next(code)
  }

  notifyLanguagesChanged(): void {
    this.languagesChangedEvent.next()
  }

  notifyLanguageSelected(code: LanguageCode): void {
    this.languageSelectedEvent.next(code)
  }

  notifyContactClicked(asset: IAsset): void {
    this.contactClickedEvent.next(asset)
  }

  notifyChipRemoved(chip: IChip): void {
    this.chipRemovedEvent.next(chip)
  }

  notifyAllChipsRemoved(): void {
    this.allChipsRemovedEvent.next()
  }

  notifyFilterClicked(): void {
    this.filterClickedEvent.next()
  }

  notifyMediaLibraryFileUploaded(data: { originalFileName: string; fileName: string }) {
    this.mediaLibraryUploadedEvent.next(data)
  }

  notifyMediaLibraryFileUploadFailed(data: { originalFileName: string }) {
    this.mediaLibraryUploadedFailedEvent.next(data)
  }

  notifyIframeNeedsRefresh() {
    this.iframeNeedsRefreshEvent.next()
  }

  notifyFilterChanged() {
    this.filterChangedEvent.next()
  }

  notifyClearComparisonAsset(equipmentNumber: string) {
    this.clearComparisonAssetEvent.next(equipmentNumber)
  }

  notifyClearAllComparisonAssets() {
    this.clearAllComparisonAssetsEvent.next()
  }
}
