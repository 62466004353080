import { WritableSignal } from '@angular/core'
import { IConfig } from 'src/app/core/interfaces/config.interface'
import { IConversionProcessor } from './processors'
import { defaultHeaderMenuItems } from '../data/header-menu.data'

export const processV4toV5: IConversionProcessor = {
  endVersion: '5',
  process(config: WritableSignal<IConfig>): void {
    updateHeaderMenu(config)
  }
}

const updateHeaderMenu = (config: WritableSignal<any>) => {
  if (!config().header?.data?.menu || config().header.data.menu.length === 0) {
    config().header.data.menu = [ ...defaultHeaderMenuItems ]
  }
}
