import { IHeaderMenuItem } from '../interfaces/header.interface'
import { RoutePaths } from './route-paths.data'

export const headerMenuEquipmentLink: IHeaderMenuItem = {
  label: {
    'en-US': 'Equipment',
    'en-CA': 'Equipment',
    'es-MX': 'Equipo',
    'es-SP': 'Equipo',
    'fr-CA': 'Équipements',
  },
  type: 'internal',
  routerLink: RoutePaths.Search,
}

export const defaultHeaderMenuItems: IHeaderMenuItem[] = [{
  label: {
    'en-US': 'Home',
    'en-CA': 'Home',
    'es-MX': 'Inicio',
    'es-SP': 'Inicio',
    'fr-CA': 'Accueil',
  },
  type: 'internal',
  routerLink: '/',
}, headerMenuEquipmentLink, {
  label: {
    'en-US': 'Contact',
    'en-CA': 'Contact',
    'es-MX': 'Contacto',
    'es-SP': 'Contacto',
    'fr-CA': 'Contact',
  },
  type: 'contact',
}]
