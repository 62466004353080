@if (href) {
  <a
    mat-button
    data-testid="icon-button"
    [disabled]="disabled"
    class="IconButton mat-mdc-icon-button"
    [class.IconButton--primary]="themeStyle === IconButtonThemeStyle.Primary"
    [class.IconButton--secondary]="themeStyle === IconButtonThemeStyle.Secondary"
    [class.IconButton--white]="themeStyle === IconButtonThemeStyle.White"
    [class.IconButton--black]="themeStyle === IconButtonThemeStyle.Black"
    [class.IconButton--medium]="themeSize === IconButtonThemeSize.Medium"
    [class.IconButton--small]="themeSize === IconButtonThemeSize.Small"
    [class.IconButton--icon-large]="iconSize === IconButtonIconSize.Large"
    [class.IconButton--icon-medium]="iconSize === IconButtonIconSize.Medium"
    [class.IconButton--icon-small]="iconSize === IconButtonIconSize.Small"
    [class.IconButton--icon-xsmall]="iconSize === IconButtonIconSize.XSmall"
    [class.mat-mdc-outlined-button]="themeType === IconButtonThemeType.Outlined"
    (click)="onClick()"
    [href]="href"
    [style]="styleOverrides"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
} @else {
  <button
    mat-button
    data-testid="icon-button"
    [disabled]="disabled"
    class="IconButton mat-mdc-icon-button"
    [class.IconButton--primary]="themeStyle === IconButtonThemeStyle.Primary"
    [class.IconButton--secondary]="themeStyle === IconButtonThemeStyle.Secondary"
    [class.IconButton--white]="themeStyle === IconButtonThemeStyle.White"
    [class.IconButton--black]="themeStyle === IconButtonThemeStyle.Black"
    [class.IconButton--medium]="themeSize === IconButtonThemeSize.Medium"
    [class.IconButton--small]="themeSize === IconButtonThemeSize.Small"
    [class.IconButton--icon-large]="iconSize === IconButtonIconSize.Large"
    [class.IconButton--icon-medium]="iconSize === IconButtonIconSize.Medium"
    [class.IconButton--icon-small]="iconSize === IconButtonIconSize.Small"
    [class.IconButton--icon-xsmall]="iconSize === IconButtonIconSize.XSmall"
    [class.mat-mdc-outlined-button]="themeType === IconButtonThemeType.Outlined"
    [style]="styleOverrides"
    [attr.aria-label]="ariaLabel"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>
}
<ng-template #content>
  <div class="content-wrapper">
    <mat-icon>{{matIcon}}</mat-icon>
  </div>
</ng-template>