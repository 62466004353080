import { Component, Inject, Injectable, inject } from '@angular/core'
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBar,
  MatSnackBarRef,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar'
import { MatIconModule } from '@angular/material/icon'
import { MatButtonModule } from '@angular/material/button'
import { ISnackbarData, IToastOptions } from '../interfaces/toast.interface'

@Injectable()
export class ToastService {
  constructor(
    private _snackBar: MatSnackBar,
  ) {}

  success(options: IToastOptions) {
    const {
      icon = 'check_circle',
      message = 'Success!',
      action = null,
      includeClose = false,
      duration = 5000,
      hPos = 'center',
      vPos = 'bottom',
    } = options
    this._runSnackbar({
      icon,
      message,
      action,
      includeClose,
    }, duration, ['success-toast'], hPos, vPos)
  }

  error(options: IToastOptions) {
    const {
      icon = 'error_outline',
      message = 'Error!',
      action = null,
      includeClose = false,
      duration = 5000,
      hPos = 'center',
      vPos = 'bottom',
    } = options
    this._runSnackbar({
      icon,
      message,
      action,
      includeClose,
    }, duration, ['error-toast'], hPos, vPos)
  }

  private _runSnackbar(
    data: ISnackbarData,
    duration: number,
    panelClass: string[],
    horizontalPosition: MatSnackBarHorizontalPosition,
    verticalPosition: MatSnackBarVerticalPosition,
  ) {
    this._snackBar.openFromComponent(ToastContentComponent, {
      data,
      duration,
      panelClass,
      horizontalPosition,
      verticalPosition,
    })
  }
}

@Component({
  selector: 'app-toast-content',
  template: `
    @if (data.icon) {
      <mat-icon class="icon">{{data.icon}}</mat-icon>
    }
    @if (data.message && checkMessageString()) {
      <div class="message">{{data.message}}</div>
    } @else if (data.message) {
      <div class="message">
        @for (msg of data.message; track $index) {
          <div>{{msg}}</div>
        }
      </div>
    }
    @if (data.action) {
      <button
        class="action"
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()"
      >{{data.action}}</button>
    }
    @if (data.includeClose) {
      <button
        class="close"
        mat-button
        matSnackBarAction
        (click)="snackBarRef.dismissWithAction()"
      ><mat-icon>close</mat-icon></button>
    }
  `,
  styles: [`
    :host {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .icon {
      min-width: 24px;
    }
    .message {
      flex-grow: 1;
    }
    .action {
      font-weight: bold;
      color: inherit;
    }
    mat-icon {
      margin: 0 !important;
    }
    .mdc-button.close {
      padding: 0 !important;
      min-width: 32px;
      color: inherit;
    }
  `],
  imports: [MatIconModule, MatButtonModule]
})
export class ToastContentComponent {
  snackBarRef = inject(MatSnackBarRef)
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
  ) {}
  checkMessageString(): boolean {
    return typeof this.data.message === 'string'
  }
}
