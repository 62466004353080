
export interface IMediaData {
  kb: number,
  name: string,
  originalFileName: string,
  updated: string,
  updatedFormatted: string,
  width: number,
  height: number,
  urls: {
    [key: string]: string
  }
  loaded?: boolean,
}

export interface IMediaLibraryResponse {
  results: IMediaData[]
}

export interface IFileUploadResponse {
  filename: string
  success: boolean
}

export interface IDeleteResponse {
  success: boolean
  deleted_files: string[],
  failed_to_delete: [
    {
      name: string,
      error: string,
      error_code: number
    }?
  ],
}

export enum ImageSizes {
  Thumbnail = '250x0xc',
  Full = '0x0xc',
  Unsized = 'unsized',
  Favicon = '32x32xc',
  DefaultProductImage = '648x486xc',
  SocialMediaShare = '1200x630xc',
  HeroBannerAspectRatio = '2000x0xc',
  HeroBannerSmall = '2000x800xc',
  HeroBannerMedium = '2000x1500xc',
  HeroBannerLarge = '2000x1800xc',
}

export const EMPTY_MEDIA_DATA: IMediaData = {
  kb: 0,
  name: '',
  originalFileName: '',
  updated: '',
  updatedFormatted: '',
  width: 100,
  height: 100,
  urls: {
    full: '',
    thumbnail: '',
    unsized: '',
  },
}
