import { Injectable, inject } from '@angular/core'
import { KeysService } from './keys.service'
import { LogService } from './log.service'
import { environment } from 'src/environments/environment'
import { ClientService } from './client.service'

declare global {
  interface Window {
    heap: any
  }
}

@Injectable()
export class HeapService {
  private _logger: LogService = inject(LogService)
  private keysService: KeysService = inject(KeysService)
  protected clientService = inject(ClientService)
  window = inject(Window)

  init() {
    this._logger.debug('HeapService.init()')
    this.loadHeapAnalytics()
  }

  private loadHeapAnalytics() {
    this._logger.debug('HeapService.loadHeapAnalytics()')
    const keys = this.keysService.keys()
    const heapKey = environment.heap.appId

    if (!this.window?.heap && !(typeof window?.heap?.load === 'function')) { return }

    this.window.heap.load(heapKey)
    this.window.heap.addUserProperties({
      client_name: this.clientService.client().name,
      client_code: this.clientService.client().sales_code,
      domain: this.clientService.client().domain
    })
    this._logger.debug('HeapService.loadHeapAnalytics() - Heap.io loaded with key: ', heapKey)

    if (!keys.heap_key) {
      this._logger.debug('HeapService.loadHeapAnalytics() - No key found')
      return
    }


    if (this.window.heap.loaded) { return }

    this.window.heap.load(keys.heap_key)
  }

  trackEvent(event: string, properties: any) {
    if (!this.window.heap) {
      this._logger.warn('HeapService.trackEvent() - Failed to track event.  Heap not loaded.')
      return
    }
    this.window.heap.track(event, properties)
  }
}
