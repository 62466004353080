import { Injectable } from '@angular/core'
import { allConfigs, mockClientData, mockConfigData, mockKeysData } from '../mock-data/config.mock-data'
import { IConfig } from 'src/app/core/interfaces/config.interface'
import { browseByCategoryData, browseByMakeData, buildAssets } from '../mock-data/assets.mock-data'
import { ISearchResults } from '../interfaces/search.interface'
import { currencyRates, defaultCurrency, supportedCurrencies } from '../mock-data/currency.mock-data'
import { ICurrency, ICurrencyRateData } from '../interfaces/currency.interface'
import { IAssetCategoriesResponse, IAssetCategory, IAssetsResponse, IMake, IMakeResponse } from '../interfaces/asset.interface'
import { IKeys } from '../interfaces/keys.interface'
import { IApiAllConfigsResponse } from '../interfaces/init-data.interface'
import { IPagination } from '../interfaces/api-request.interface'
import { IClient } from '../interfaces/client.interface'
import { supportedLanguages } from '../data/languages.data'
import { ILanguage } from '../interfaces/language.interface'
import { xyzMockConfigData } from '../mock-data/xyz.mock-data'
import { IFilterItem } from '../interfaces/filter.interface'
import { mockFilterSettings } from '../mock-data/filters.mock-data'

@Injectable({
  providedIn: 'root'
})
export class MockService {
  getMockConfig(src: string = 'default'): IConfig {
    switch(src) {
      case 'xyz':
        return xyzMockConfigData
      default:
        return mockConfigData
    }
  }

  getMockClient(): IClient {
    return mockClientData
  }

  getMockKeys(): IKeys {
    return mockKeysData
  }

  getFilterSettings(): Record<string, IFilterItem[]>{
    return {
      category: mockFilterSettings,
      make: mockFilterSettings,
      year: mockFilterSettings,
      branch_country: mockFilterSettings,
    }
  }

  getAllConfigs(): IApiAllConfigsResponse {
    return allConfigs
  }

  getSearchResults(term: string): ISearchResults {
    if (!term || term.length < 3) {
      return {
        results: {
          make: [],
          model: [],
          category: [],
          subcategory: [],
          branch_state: [],
          display_equipment_number: [],
        }
      }
    }

    return {
      results: {
        make: [{ field: 'make', value: 'Caterpillar', count: 1 }],
        model: [{ field: 'model', value: 'D6', count: 1 }],
        category: [{ field: 'category', value: 'Dozer', count: 1 }],
        subcategory: [{ field: 'subcategory', value: 'Crawler', count: 1 }],
        branch_state: [{ field: 'branch_state', value: 'CA', count: 1 }],
        display_equipment_number: [{ field: 'display_equipment_number', value: 'display_eq_number_0', value_id: 'eq_number_0', count: 1 }],
      }
    }
  }

  getAssetCategories(pagination: IPagination): IAssetCategoriesResponse {
    let data: IAssetCategory[] = []
    switch (pagination?.field) {
      case 'alpha':
        if (pagination.direction === 'desc') {
          data = browseByCategoryData.sort((a, b) => a.category.localeCompare(b.category))
        } else {
          data = browseByCategoryData.sort((a, b) => -1 * a.category.localeCompare(b.category))
        }
        break
      case 'quantity':
        if (pagination.direction === 'asc') {
          data = browseByCategoryData.sort((a, b) => a.quantity - b.quantity)
        } else {
          data = browseByCategoryData.sort((a, b) => b.quantity - a.quantity)
        }
        break
    }
    return {
      data: data.slice().splice(0, pagination.size),
      pagination: {
        total_items: pagination.size,
        total_pages: 1,
        direction: pagination.direction,
        field: pagination.field,
        index: 0,
        size: pagination.size,
      },
    }
  }

  getAssets(pagination: IPagination = {
    direction: 'asc',
    field: 'equipment_number',
    index: 0,
    size: 10
  }): IAssetsResponse {
    return {
      data: buildAssets(pagination.size),
      pagination: {
        total_items: pagination.size,
        total_pages: 1,
        direction: pagination.direction,
        field: pagination.field,
        index: 0,
        size: pagination.size,
      },
    }
  }

  getCurrencyRates(): ICurrencyRateData[] {
    return currencyRates
  }

  getDefaultCurrency(): ICurrency {
    return defaultCurrency
  }

  getCurrencies(): ICurrency[] {
    return supportedCurrencies
  }

  getCurrenciesWithRates(): ICurrency[] {
    return supportedCurrencies.map(currency => {
      const rate = currencyRates.find(rate => rate.currency === currency.code)?.rate
      return {
        ...currency,
        rate,
      }
    })
  }

  getLanguages(): ILanguage[] {
    return supportedLanguages
  }

  getMakes(): IMakeResponse {
    const data: IMake[] = browseByMakeData
    return {
      results: data
    }
  }

  submitContactForm() {
    return {
      success: false,
    }
  }
}
